@use '@checkout-ui/design-system' as ds;

.banner {
  padding: ds.$space-150;
  border-radius: ds.$corner-radius-l;

  &.default {
    background: ds.$grey-100;
  }

  &.info {
    background: ds.$functional-blue-light;
  }

  &.infoNeutral {
    background: ds.$grey-50;
    border: 0.5px solid ds.$grey-200;
    border-radius: ds.$corner-radius-m;
  }

  &.error {
    background: ds.$functional-red-light;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: ds.$space-100;
  }
  &__icon {
    margin-right: ds.$space-100;
  }
}

@use 'typography';

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  font-family: typography.$font-family-primary;
}

code {
  font-family: typography.$font-family-monospace;
}

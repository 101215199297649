@use '@checkout-ui/design-system' as ds;

.btn {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  @include ds.font-text-button;

  &:disabled {
    pointer-events: none;
  }

  &:active {
    box-shadow: none;
  }
}

@use '@checkout-ui/design-system' as ds;

.integration {
  &__country {
    margin: ds.$space-200 0;
  }

  &__language {
    margin-bottom: ds.$space-500;
  }

  &__type {
    .content {
      border: 1px solid ds.$grey-400;
      border-radius: ds.$corner-radius-s;
      padding: ds.$space-300 ds.$space-300;
      margin-top: ds.$space-200;
      margin-bottom: ds.$space-200;

      &__item {
        margin-bottom: ds.$space-200;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__cta {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: ds.$space-200 ds.$space-500 ds.$space-400;
  }
}

@use '@checkout-ui/design-system' as ds;

.heading {
  margin: 0;

  &.heading-1 {
    @include ds.font-header-page;
  }

  &.heading-2 {
    @include ds.font-header-heading;
  }

  // Remove after redesign - no current replacement
  // used for klarna main widget only
  &.heading-3 {
    @include ds.heading-3;
  }
}

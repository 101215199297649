@use '@checkout-ui/design-system' as ds;

.radio-icon__circle--big--selected {
  fill: ds.$grey-900;
}

.radio-icon__circle--small--selected {
  fill: ds.$grey-100;
}

.radio-icon__circle--big--unselected {
  stroke: ds.$grey-900;
}

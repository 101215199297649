@import 'include-media/dist/include-media';

/* New Breakpoints */
$breakpoints: (
  //Max modal content width
  'modal-max-width': 360px,

  // Modal size breakpoints for direct and hpp
  'phone': 408px,
  'tablet': 480px,

  // Breakpoint to jump to reduce modal size in Klarna
  'klarna-tablet': 420px,

  'all-mobile': 420px,
  //https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
  'screen-sm': 576px,
  'screen-md': 768px,
  'screen-lg': 992px,
  'screen-xl': 1200px
);

// The max width of the modal content
$content-max-width: 360px;
//Mixin to have modal max content width aligned
@mixin modal-width {
  width: $content-max-width;
}

/** Breakpoint mixins **/
// Phone - S, Phone - M
@mixin screen-lt-sm {
  @include media('<phone') {
    @content;
  }
}

// For screens smaller than 420px
//FIXME revisit breakpoints and revisit their namings. a breakpoint should work for all phones.
//FIXME https://ozean12.atlassian.net/browse/PURCHASE-1588
@mixin screen-less-than-420 {
  @include media('<all-mobile') {
    @content;
  }
}

// Phone - L, Tablet - S
@mixin screen-ge-sm-and-le-md {
  @include media('>=phone', '<=tablet') {
    @content;
  }
}

// Tablet M and bigger.
@mixin screen-gt-md {
  @include media('>tablet') {
    @content;
  }
}

// Modal content width is smaller then content-max-width
@mixin widget-lt-sm {
  @include media('<=modal-max-width') {
    @content;
  }
}

// Modal content width is bigger then content-max-width
@mixin widget-ge-sm {
  @include media('>=modal-max-width') {
    @content;
  }
}

// Klarna Table M and bigger breakpoint
@mixin widget-klarna-ge-md {
  @include media('>=klarna-tablet') {
    @content;
  }
}

// FIXME:PURCHASE-1465 FE - clean not-used breakpoints after HPP redesign completed
// X-Small devices (portrait phones, less than 576px)
@mixin xs {
  @include media('<screen-sm') {
    @content;
  }
}

// Small devices (landscape phones, 576px and up)
@mixin sm {
  @include media('>=screen-sm') {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin md {
  @include media('>=screen-md') {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin lg {
  @include media('>=screen-lg') {
    @content;
  }
}

// Custom devices
@mixin customBreakpoint($screen) {
  @media (min-width: #{$screen}px) {
    @content;
  }
}

//Variables and export, used for react-media only
//FIXME: PURCHASE-1466 FE - remove css variables after removing react-media
$width-xs: 375px;
$width-sm: 420px;
$screen-md: 768px;
$screen-lg: 992px;

:export {
  xs: $width-xs;
  sm: $width-sm;
  screen_md: $screen-md;
  screen_lg: $screen-lg;
}

.container {
  display: inline-block;
}

.md {
  height: 20px;
  width: 59px;
}

.sm {
  height: 16px;
  width: 47px;
}

.xs {
  height: 10px;
  width: 30px;
}

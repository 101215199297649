@use '@checkout-ui/design-system' as ds;

.btn--external {
  background-color: ds.$grey-900;
  color: ds.$grey-000;
  border-radius: 4px !important;

  &.medium {
    @include ds.button-size-medium;
  }

  &.large {
    @include ds.button-size-large;
  }

  &:disabled {
    background-color: ds.$grey-100;
  }

  &:active {
    background-color: ds.$grey-900;
  }
}

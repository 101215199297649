$unit: 8px;

// New Spacing
$space-0: ($unit * 0);
$space-025: ($unit * 0.25);
$space-050: ($unit * 0.5);
$space-075: ($unit * 0.75);
$space-100: ($unit * 1);
// FIXME: not present in design system! but still used for inputs
$space-125: ($unit * 1.25);
$space-150: ($unit * 1.5);
$space-200: ($unit * 2);
$space-250: ($unit * 2.5);
$space-300: ($unit * 3);
$space-375: ($unit * 3.75); // 30px
$space-400: ($unit * 4);
$space-450: ($unit * 4.5); // 36px
$space-500: ($unit * 5);
$space-600: ($unit * 6);
$space-700: ($unit * 7);
$space-800: ($unit * 8);
$space-900: ($unit * 9);
$space-1000: ($unit * 10);
$space-1200: ($unit * 12);
$space-2000: ($unit * 20);

.container {
  width: fit-content;
  display: inline-block;
}

.icon {
  flex-shrink: 0; //keep size consistent when wrapped in flex items
}

.xs {
  height: 12px;
  width: 12px;
}

.sm {
  height: 16px;
  width: 16px;
}

.md {
  height: 24px;
  width: 24px;
}

.lg {
  height: 42px;
  width: 42px;
}

@use '@checkout-ui/design-system' as ds;
.container {
  display: flex;
  align-items: center;
  .menu {
    position: absolute;
    left: ds.$space-200;
    top: ds.$space-250;
    @include ds.lg {
      display: none;
    }
  }

  .viewport {
    display: block;
    visibility: hidden;
    color: ds.$grey-900;
    border: 1px solid ds.$grey-400;
    border-radius: 4px;
    margin: ds.$space-050;
    height: ds.$space-700;
    box-sizing: border-box;

    &__placeholder {
      display: block;
      color: ds.$grey-900;
      margin: ds.$space-050;
      height: ds.$space-700;
    }

    @include ds.md {
      display: flex;
      visibility: visible;
    }

    &__item {
      width: 120px;
      padding-bottom: 0;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: unset;

      &:before {
        content: '';
        display: block;
        width: 1px;
        background: ds.$grey-400;
        height: ds.$space-500;
        position: absolute;
        right: 0;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
      &--desktop {
        display: none;
        @include ds.lg {
          display: flex;
        }
      }
      &--tablet {
        display: none;
        @include ds.md {
          display: flex;
        }
      }
      &--mobile {
        display: none;
        @include ds.md {
          display: flex;
        }
      }
      &--active,
      &:hover {
        background: ds.$grey-900;
        color: ds.$grey-000;
        border-color: ds.$grey-900;
        &:before {
          background: ds.$grey-900;
        }
      }
      &:hover {
        cursor: pointer;
      }
      span {
        padding-top: ds.$space-050;
      }
    }
  }
}

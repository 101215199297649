@use '@checkout-ui/design-system' as ds;

.input-label {
  @include ds.font-body-regular;

  cursor: pointer;
  margin-right: ds.$space-100;
  margin-left: ds.$space-100;

  &.disabled {
    cursor: default;
    color: ds.$grey-600;
  }
}

// FONT FAMILY
// fallback fonts are using standard system fonts, depending on the os user has
// read more: https://booking.design/implementing-system-fonts-on-booking-com-a-lesson-learned-bdc984df627f
$font-family-primary: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif;
// duplicate monospace is intentional, read more: https://github.com/necolas/normalize.css/issues/519#issuecomment-197131966, https://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$font-family-monospace: 'Source Code Pro', monospace, monospace;

// FONT WEIGHT DESKTOP
$font-weight-regular-desktop: 400;
$font-weight-medium-desktop: 500;
$font-weight-bold-desktop: 600;
// FONT WEIGHT MOBILE
$font-weight-regular-mobile: 400;
$font-weight-medium-mobile: 500;
$font-weight-bold-mobile: 600;

// FONT SIZE DESKTOP
$font-size-xs-desktop: 11px;
$font-size-s-desktop: 14px;
$font-size-m-desktop: 17px;
$font-size-l-desktop: 24px;
$font-size-xl-desktop: 32px;
// FONT SIZE MOBILE
$font-size-xs-mobile: 11px;
$font-size-s-mobile: 15px;
$font-size-m-mobile: 17px;
$font-size-l-mobile: 24px;
$font-size-xl-mobile: 28px;

// FIXME: remove after finishing the epic
// FONT SIZE
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-21: 21px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-40: 40px;

// LINE HEIGHT DESKTOP
$line-height-xs-desktop: 12px;
$line-height-s-desktop: 16px;
$line-height-m-desktop: 20px;
$line-height-l-desktop: 24px;
$line-height-xl-desktop: 28px;
$line-height-2xl-desktop: 32px;
$line-height-3xl-desktop: 36px;
// LINE HEIGHT MOBILE
$line-height-xs-mobile: 12px;
$line-height-s-mobile: 16px;
$line-height-m-mobile: 20px;
$line-height-l-mobile: 24px;
$line-height-xl-mobile: 28px;
$line-height-2xl-mobile: 32px;
$line-height-3xl-mobile: 32px;

// LINE HEIGHT
$line-height-48: 48px;
$line-height-36: 36px;
$line-height-32: 32px;
$line-height-28: 28px;
$line-height-24: 24px;
$line-height-20: 20px;
$line-height-16: 16px;

// LETTER SPACING DESKTOP
$letter-spacing-s-desktop: 0;
$letter-spacing-m-desktop: 0;
// LETTER SPACING MOBILE
$letter-spacing-s-mobile: 0;
$letter-spacing-m-mobile: 0;

// LETTER SPACING
$letter-spacing-0: 0px;
$letter-spacing-2: 0.2px;
$letter-spacing-4: 0.4px;
$letter-spacing-6: 0.6px;
$letter-spacing-8: 0.8px;
$letter-spacing-12: 1.2px;

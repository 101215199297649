@use 'colors' as colors;
@use 'breakpoints';
@use 'border-radius' as borderRadius;
@use 'spacings' as spacings;

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin fadeIn {
  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin border-style-default {
  border: 0.5px solid colors.$grey-600;
}

@mixin border-style-active {
  border: 0.5px solid colors.$interactions-primary-default;
}

@mixin border-style-error {
  border: 0.5px solid colors.$functional-red-default;
}

@mixin border-style-hover {
  border: 0.5px solid colors.$grey-900;
}

@mixin input-border-default {
  outline: colors.$grey-600 solid 0.5px;
  outline-offset: -1px;
  border-radius: borderRadius.$corner-radius-m;

  @include breakpoints.screen-gt-md {
    border-radius: borderRadius.$corner-radius-s;
  }
}

@mixin input-border-hover {
  @include input-border-default;

  outline-color: colors.$grey-900;
}

@mixin input-border-focus {
  @include input-border-default;

  outline-color: colors.$interactions-primary-default;
  outline-width: 1px;
  outline-offset: -1px;
}

@mixin input-border-error {
  @include input-border-default;

  outline-color: colors.$functional-red-default;
}

@mixin nowrap-with-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
}

@mixin button-size-medium {
  padding: 10px;
  border-radius: borderRadius.$corner-radius-m;
}

@mixin button-size-large {
  padding: 14px;
  border-radius: borderRadius.$corner-radius-l;
}

@mixin text-button-medium {
  padding: spacings.$space-075;
  border-radius: borderRadius.$corner-radius-s;
}

@mixin text-button-small {
  border-radius: borderRadius.$corner-radius-s;
}

@mixin text-button-medium-hover {
  &:hover {
    background-color: colors.$grey-200;
    color: colors.$grey-900;
  }
}

@mixin text-button-small-hover {
  &:hover {
    color: colors.$grey-900;
  }
}

@mixin selector-more-specific {
  &#{&} {
    @content;
  }
}

@mixin link-components-style {
  display: inline;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  padding: 0;
  border: none;
  background: transparent;
  text-decoration: none;
}

@mixin sticky-button-container {
  position: sticky;
  bottom: 0;
  display: flex;
  gap: spacings.$space-100;
}

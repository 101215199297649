//grey
$grey-900: rgb(26, 26, 26);
$grey-800: rgb(74, 74, 74);
$grey-700: rgb(97, 97, 97);
$grey-600: rgb(181, 181, 181);
$grey-400: rgb(204, 204, 204);
$grey-300: rgb(227, 227, 227);
$grey-200: rgb(235, 235, 235);
$grey-100: rgb(241, 241, 241);
$grey-50: rgb(253, 253, 253);
$grey-000: rgb(255, 255, 255);

//functional
$functional-red-dark: rgb(142, 31, 11);
$functional-red-default: rgb(199, 0, 0);
$functional-red-light: rgb(254, 233, 232);
$functional-red-vibrant: rgb(229, 28, 0);

$functional-green-dark: rgb(12, 81, 50);
$functional-green-default: rgb(44, 192, 49);
$functional-green-light: rgb(234, 255, 242);
$functional-green-vibrant: rgb(64, 212, 69);

$functional-orange-dark: rgb(134, 65, 0);
$functional-orange-default: rgb(220, 123, 8);
$functional-orange-light: rgb(255, 244, 234);
$functional-orange-vibrant: rgb(250, 153, 38);

$functional-blue-dark: rgb(0, 74, 138);
$functional-blue-default: rgb(53, 147, 214);
$functional-blue-light: rgb(236, 247, 255);
$functional-blue-vibrant: rgb(83, 177, 244);

$interactions-primary-dark: rgb(26, 65, 177);
$interactions-primary-default: rgb(38, 91, 245);
$interactions-primary-light: rgb(235, 240, 255);

// Decoration Colors
$decoration-pink: rgb(223, 26, 121);
$decoration-purple: rgb(102, 0, 245);
$decoration-yellow: rgb(255, 208, 0);

:export {
  decoration: {
    pink: $decoration-pink;
    purple: $decoration-purple;
    yellow: $decoration-yellow;
  }
  functional_green: {
    green_dark: $functional-green-dark;
    green_default: $functional-green-default;
    green_light: $functional-green-light;
    green_vibrant: $functional-green-vibrant;
  }

  functional_blue: {
    blue_dark: $functional-blue-dark;
    blue_default: $functional-blue-default;
    blue_light: $functional-blue-light;
    blue_vibrant: $functional-blue-vibrant;
  }

  functional_orange: {
    orange_dark: $functional-orange-dark;
    orange_default: $functional-orange-default;
    orange_light: $functional-orange-light;
    orange_vibrant: $functional-orange-vibrant;
  }
  functional_red: {
    red_dark: $functional-red-dark;
    red_default: $functional-red-default;
    red_light: $functional-red-light;
    red_vibrant: $functional-red-vibrant;
  }

  dark_grey: {
    grey900: $grey-900;
    grey800: $grey-800;
    grey700: $grey-700;
    grey600: $grey-600;
    grey400: $grey-400;
  }
  light_grey: {
    grey300: $grey-300;
    grey200: $grey-200;
    grey100: $grey-100;
    grey50: $grey-50;
    grey000: $grey-000;
  }
}

@use '@checkout-ui/design-system' as ds;

.spinner {
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;

  &--primary {
    fill: ds.$grey-000;
  }

  &--secondary {
    fill: ds.$grey-900;
  }

  &--neutral {
    fill: ds.$grey-400;
  }

  @include ds.keyframes(loading) {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

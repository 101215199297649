@use '@checkout-ui/design-system' as ds;

.body-text {
  &-1 {
    @include ds.body-1;
  }

  &-2 {
    @include ds.body-2;
  }

  &-regular {
    @include ds.font-body-regular;
  }

  &-subheading {
    @include ds.font-header-subheading;
  }
}

@use '@checkout-ui/design-system' as ds;

.link {
  @include ds.link-components-style;
  &:hover {
    color: ds.$decoration-purple;
  }

  &.underline {
    text-decoration: underline;
  }

  & > * {
    vertical-align: middle;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: ds.$space-100;
}

.text-button-medium {
  color: ds.$grey-800;
  @include ds.font-body-regular;
  @include ds.text-button-medium;
  @include ds.text-button-medium-hover;
}

.text-button-small {
  color: ds.$grey-700;
  @include ds.font-body-medium;
  @include ds.text-button-small;
  @include ds.text-button-small-hover;
}

@use 'breakpoints';
@use 'typography-base' as base;

// Always prefer using the mixins
// Use the base values only of absolute necessity
@forward 'typography-base';

@mixin font-text-regular {
  font-size: base.$font-size-xs-mobile;
  line-height: base.$line-height-s-mobile;
  font-weight: base.$font-weight-regular-mobile;
  letter-spacing: base.$letter-spacing-s-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-xs-desktop;
    line-height: base.$line-height-s-desktop;
    font-weight: base.$font-weight-regular-desktop;
    letter-spacing: base.$letter-spacing-s-desktop;
  }
}

@mixin font-text-bold {
  font-size: base.$font-size-xs-mobile;
  line-height: base.$line-height-s-mobile;
  font-weight: base.$font-weight-medium-mobile;
  letter-spacing: base.$letter-spacing-s-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-xs-desktop;
    line-height: base.$line-height-s-desktop;
    font-weight: base.$font-weight-medium-desktop;
    letter-spacing: base.$letter-spacing-s-desktop;
  }
}

@mixin font-text-button {
  font-size: base.$font-size-s-mobile;
  line-height: base.$line-height-xs-mobile;
  font-weight: base.$font-weight-medium-mobile;
  letter-spacing: base.$letter-spacing-s-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-s-desktop;
    line-height: base.$line-height-xs-desktop;
    font-weight: base.$font-weight-medium-desktop;
    letter-spacing: base.$letter-spacing-s-desktop;
  }
}

@mixin font-body-regular {
  font-size: base.$font-size-s-mobile;
  line-height: base.$line-height-m-mobile;
  font-weight: base.$font-weight-regular-mobile;
  letter-spacing: base.$letter-spacing-s-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-s-desktop;
    line-height: base.$line-height-m-desktop;
    font-weight: base.$font-weight-regular-desktop;
    letter-spacing: base.$letter-spacing-s-desktop;
  }
}

@mixin font-body-medium {
  font-size: base.$font-size-s-mobile;
  line-height: base.$line-height-m-mobile;
  font-weight: base.$font-weight-medium-mobile;
  letter-spacing: base.$letter-spacing-s-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-s-desktop;
    line-height: base.$line-height-m-desktop;
    font-weight: base.$font-weight-medium-desktop;
    letter-spacing: base.$letter-spacing-s-desktop;
  }
}

@mixin font-body-bold {
  font-size: base.$font-size-s-mobile;
  line-height: base.$line-height-m-mobile;
  font-weight: base.$font-weight-bold-mobile;
  letter-spacing: base.$letter-spacing-s-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-s-desktop;
    line-height: base.$line-height-m-desktop;
    font-weight: base.$font-weight-bold-desktop;
    letter-spacing: base.$letter-spacing-s-desktop;
  }
}

@mixin font-header-subheading {
  font-size: base.$font-size-s-mobile;
  line-height: base.$line-height-m-mobile;
  font-weight: base.$font-weight-medium-mobile;
  letter-spacing: base.$letter-spacing-s-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-s-desktop;
    line-height: base.$line-height-m-desktop;
    font-weight: base.$font-weight-medium-desktop;
    letter-spacing: base.$letter-spacing-s-desktop;
  }
}

@mixin font-header-heading {
  font-size: base.$font-size-m-mobile;
  line-height: base.$line-height-l-mobile;
  font-weight: base.$font-weight-bold-mobile;
  letter-spacing: base.$letter-spacing-s-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-m-desktop;
    line-height: base.$line-height-l-desktop;
    font-weight: base.$font-weight-bold-desktop;
    letter-spacing: base.$letter-spacing-s-desktop;
  }
}

@mixin font-header-page {
  font-size: base.$font-size-l-mobile;
  line-height: base.$line-height-xl-mobile;
  font-weight: base.$font-weight-medium-mobile;
  letter-spacing: base.$letter-spacing-m-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-l-desktop;
    line-height: base.$line-height-xl-desktop;
    font-weight: base.$font-weight-medium-desktop;
    letter-spacing: base.$letter-spacing-m-desktop;
  }
}

@mixin font-display-medium {
  font-size: base.$font-size-l-mobile;
  line-height: base.$line-height-2xl-mobile;
  font-weight: base.$font-weight-bold-mobile;
  letter-spacing: base.$letter-spacing-m-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-l-desktop;
    line-height: base.$line-height-2xl-desktop;
    font-weight: base.$font-weight-bold-desktop;
    letter-spacing: base.$letter-spacing-m-desktop;
  }
}

@mixin font-display-large {
  font-size: base.$font-size-xl-mobile;
  line-height: base.$line-height-3xl-mobile;
  font-weight: base.$font-weight-bold-mobile;
  letter-spacing: base.$letter-spacing-m-mobile;

  @include breakpoints.screen-gt-md {
    font-size: base.$font-size-xl-desktop;
    line-height: base.$line-height-3xl-desktop;
    font-weight: base.$font-weight-bold-desktop;
    letter-spacing: base.$letter-spacing-m-desktop;
  }
}

// MIXIN
// FIXME: remove these mixins after they become unused
@mixin body-1 {
  font-weight: base.$font-weight-regular-desktop;

  font-size: base.$font-size-16;
  line-height: base.$line-height-24;
  letter-spacing: base.$letter-spacing-2;
}

@mixin body-2 {
  font-weight: base.$font-weight-regular-desktop;

  font-size: base.$font-size-14;
  line-height: base.$line-height-20;
  letter-spacing: base.$letter-spacing-2;
}

@mixin caption {
  font-weight: base.$font-weight-regular-desktop;

  font-size: base.$font-size-12;
  line-height: base.$line-height-20;
  letter-spacing: base.$letter-spacing-2;
}

@mixin heading-3 {
  font-weight: base.$font-weight-regular-desktop;

  font-size: base.$font-size-16;
  line-height: base.$line-height-24;
  letter-spacing: base.$letter-spacing-2;
}

@mixin button {
  font-weight: base.$font-weight-bold-desktop;

  font-size: base.$font-size-16;
  line-height: base.$line-height-24;
  letter-spacing: base.$letter-spacing-2;
}

@mixin input {
  font-weight: base.$font-weight-regular-desktop;
  letter-spacing: base.$letter-spacing-s-desktop;

  font-size: base.$font-size-s-desktop;
  line-height: base.$line-height-m-desktop;
}

@mixin input-placeholder {
  font-weight: base.$font-weight-regular-desktop;
  font-size: base.$font-size-s-desktop;
  line-height: base.$line-height-m-desktop;
  letter-spacing: base.$letter-spacing-s-desktop;
}

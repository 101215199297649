@use 'sass:meta';
@use '@checkout-ui/design-system' as *;
@use '../global-styles/colors' as colors;

.text {
  // to enable using the text component nested in other components
  // example: <Input><Text>something</Text></Input>
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;

  margin: 0;

  &.text-regular {
    @include font-text-regular;
  }

  &.text-bold {
    @include font-text-bold;
  }

  &.text-button {
    @include font-text-button;
  }

  //-------- DO NOT CHANGE the order of the classes to allow using secondary font and override font weight
  // both font weight and family change the font family , for that reason we want font weight to override font family if specified

  //font weight
  &.regular {
    font-weight: $font-weight-regular-desktop;
  }

  &.bold {
    font-weight: $font-weight-medium-desktop;
  }

  &.semi-bold {
    font-weight: $font-weight-bold-desktop;
  }

  //--------end of DO NOT CHANGE block

  //color
  &.text--inherit {
    color: inherit;
  }
  &.grey--dark {
    color: $grey-600;
  }
  &.grey--medium {
    color: $grey-700;
  }
  &.grey--darker {
    color: $grey-900;
  }
  &.text--black {
    color: $grey-900;
  }
  &.text--info {
    color: $functional-blue-dark;
  }

  &.error--dark {
    color: $functional-red-default;
  }

  @each $color, $value in meta.module-variables(colors) {
    &.#{$color} {
      color: $value;
    }
  }
}

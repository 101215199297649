@use '@checkout-ui/design-system' as ds;

.container {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .content {
    padding: ds.$space-400 ds.$space-500 0;

    &__heading {
      margin-top: ds.$space-300;
    }

    &__description {
      margin-bottom: ds.$space-400;
      margin-top: ds.$space-100;
    }
  }

  &__previous-configuration {
    display: flex;
    align-items: center;
    margin-top: ds.$space-500;
    cursor: pointer;

    &__icon {
      align-self: flex-end;
      margin-right: ds.$space-150;
    }
  }
}
